<template>
  <Dialog header="Editar tags" v-model:visible="showEditTags" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true" @hide="hide">
    <div class="mb-2">
      <span class="p-fluid">
        <tags v-model:tags="tags" />
      </span>
    </div>

    <div class="mb-2">
      <InputSwitch v-model="overwriteTags" /> Desejo substituir as tags que estão cadastradas nestes arquivos.
    </div>

    <template #footer>
      <Button label="Salvar" icon="pi pi-check" @click="save" autofocus />
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import Tags from "@/components/tags/Form.vue";
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  components: { Dialog, Button, InputSwitch, Tags },
  props: ['show'],
  emits: ['hide'],
  data() {
    return {
      overwriteTags: false,
      showEditTags : false,
      tags         : [],
    }
  },
  computed: {
    ...mapGetters(['apiEndpoint']),
    ...mapGetters('nodes', ['selectedNodes'])
  },
  methods: {
    async save() {
      const body = {
        nodes: [...this.selectedNodes],
        tags: [...this.tags],
        overwrite: this.overwriteTags
      }

      try {
        await axios.post(`${this.apiEndpoint}/file/batchApplyTags`, body);
        this.tags = [];
        this.overwriteTags = false;
        this.hide();
      } catch {
        this.$toast.add({
          severity: "error",
          summary : "Houve um erro ao aplicar as tags.",
          life    : 3000,
        });
      }
    },
    hide() {
      this.$emit('hide');
    }
  },
  watch: {
    show(newVal) {
      this.showEditTags = newVal;
    }
  }
}
</script>