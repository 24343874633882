<template>
  <Sidebar
    v-model:visible="showPreview"
    class="p-sidebar-sm"
    position="full"
    @hide="callHide"
    @click="callHide"
    v-on:keyup.esc="callHide"
  >

    <div v-if="mime[0] === 'image'">
      <img :src="urlPreview" class="text-center" />
    </div>

    <div v-if="mime[0] === 'video'">
      <!-- {{ urlPreview }} -->
      <video controls>
        <source :src="urlPreview" :type="mimetype" />
        Your browser does not support the video tag.
      </video>
    </div>

    <div v-if="mime[0] === 'audio'">
      <!-- {{ urlPreview }} -->
      <audio controls>
        <source :src="urlPreview" :type="mimetype" />
        Your browser does not support the audio tag.
      </audio>
    </div>

    <div v-if="mime[0] === 'text'">
      <pre>{{ textContent }}</pre>
    </div>

    <embed :src="urlPreview" v-if="mime[1] === 'pdf'" />
  </Sidebar>
</template>

<script>
import Sidebar from "primevue/sidebar";
import axios from "axios";
import { mapGetters } from 'vuex';

export default {
  components: { Sidebar },
  emits: ["close"],
  props: ["show", "nodeId"],
  computed: {
    ...mapGetters(['apiEndpoint', 'token']),
    ...mapGetters('nodes', {file: 'getNode'}),
    mime() {
      return this.file(this.nodeId)['mimetype'].split("/");
    }
  },
  data() {
    return {
      showPreview: this.show,
      previewWidth: "width: 0%",
      textContent: "",
      urlPreview: null
    };
  },
  methods: {
    callHide() {
      this.$emit("close");
    },
  },
  watch: {
    async show(newValue) {
      // Fetches the preview URL
      const res = await axios.get(`${this.apiEndpoint}/download/url/${this.nodeId}?x-auth-token=${this.token}&inline=1&level=low`);

      this.urlPreview = res.data.url;

      if (this.mime[0] === 'text') {
        const response = await axios.get(this.urlPreview);
        this.textContent = response.data;
      }

      this.showPreview = newValue;
    },
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

video {
  max-width: 100vw;
  max-height: 100vh;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

audio {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

embed {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

pre {
  white-space: pre-wrap;
}
</style>