<template>
  <span class="p-fluid">
  <AutoComplete
    :placeholder="placeholder"
    :multiple="true"
    v-model="selectedTags"
    :suggestions="filteredTags"
    @complete="searchTag($event)"
    field="value"
    @item-select="triggerSelect"
    @item-unselect="triggerSelect"
  />
  </span>
</template>

<script>
import AutoComplete from "primevue/autocomplete";
import { mapState } from 'vuex';

export default {
  components: { AutoComplete },
  props: {
    tags: {
      type: Object
    },
    placeholder: {
      type: String,
      default: 'Tags'
    }
  },
  emits: ['update:tags'],
  data() {
    return {
      filteredTags: null,
      selectedTags: []
    };
  },
  computed: {
    ...mapState('tags', {allTags: 'tags'}),
  },
  methods: {
    triggerSelect() {
      const tagsIds = this.selectedTags.map(x => x.tagId);
      this.$emit('update:tags', tagsIds);
    },
    searchTag(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredTags = [...this.allTags];
        } else {
          this.filteredTags = this.allTags.filter((tag) => {
            return tag.value
              .toLowerCase()
              .indexOf(event.query.toLowerCase()) >= 0;
            // return country.value
            //   .toLowerCase()
            //   .startsWith(event.query.toLowerCase());
          });
        }

        // if (this.filteredTags.length === 0) {
        //   this.filteredTags.push({value: event.query});
        // }
      }, 50);
    }
  },
  mounted() {
    if (this.allTags) {
      for (let i in this.tags) {
        this.selectedTags.push(this.allTags.find((el) => el.tagId == this.tags[i]));
      }
    }
  }
};
</script>