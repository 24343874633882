<template>
    <div class="text-center mt-5" v-if="!loaded">
      <pulse />
    </div>
    <div
      class="pt-4 text-center"
      data-backround-image="@/assets/media/image/image1.jpg"
      v-if="loaded"
    >
      <figure class="mb-3 border-0" v-if="thumbUrl">
        <img :src="thumbUrl" alt="image" />
      </figure>
      <figure class="mb-3 border-0" v-if="!thumbUrl">
        <i class="far text-success fa-file" style="font-size: 4em;"></i>
      </figure>
      <h5
        class="d-flex align-items-center justify-content-center"
        v-if="!editName"
      >
        {{ name }} <i class="fas fa-pen ms-2" @click="toggleEditName"></i>
      </h5>
      <div v-if="editName">
        <input type="text" v-model.trim="name" class="form_filename" @keyup.esc="toggleEditName" @blur="validateFilename" :class="{error: !validName}" />
      </div>
      <div>
        Tamanho: <strong>{{ readableSize }}</strong>
      </div>
      <div>
        Criado em {{ readablecdate }}
      </div>
      <div v-if="readablecdate != readablemdate">
        (atualizado em {{ readablemdate }})
      </div>
      <div>
        <i
          v-for="i in rating"
          :key="i"
          class="fas fa-star full-star"
          :position="i"
          @click="markRating"
        ></i>
        <i
          v-for="i in countEmptyStars"
          :key="i"
          class="far fa-star"
          :position="i + rating"
          @click="markRating"
        ></i>
      </div>
    </div>
    <div class="card mb-0 card-body shadow-none" v-if="loaded">
      <div class="row mb-2">
        <div class="col">
          <h6>Data atribuída</h6>
          <Calendar v-model="readableadate" dateFormat="dd/mm/yy" @date-select="formatadate" />
        </div>

        <div class="col">
          <h6>Revisado</h6>
          <InputSwitch v-model="reviewed" />
        </div>
      </div>

      <div class="mb-2">
        <h6>Descrição</h6>
        <p class="text-muted mb-0">
          <textarea class="form-control" rows="3" v-model="description" />
        </p>
      </div>
      <div class="mb-2">
        <h6>Tags</h6>
        <span class="p-fluid">
          <tags v-model:tags="tags" />
        </span>

      </div>
      <div class="mb-4" v-if="exif">
        <h6>Mais detalhes <i class="fas fa-caret-down" v-if="!showExif" @click="toggleShowExif"></i>
        <i class="fas fa-caret-up" v-if="showExif" @click="toggleShowExif"></i>
        </h6>
        <div v-if="showExif">
          {{ exif }}
        </div>
      </div>
      <div class="mb-4 btn-controls">
        <a href="javascript:;" class="btn btn-primary me-1" v-if="uploaded" @click="downloadFile"
          ><i class="fas fa-download"></i
        ></a>
        <a href="javascript:;" class="btn btn-primary me-1" @click="deleteFile" v-if="uploaded && hasPermission(permissionsConstants.FILE_DELETE)"
          ><i class="fas fa-trash-alt"></i
        ></a>
        <!-- <a href="javascript:;" class="btn btn-primary me-3" v-if="uploaded"
          ><i class="fas fa-share-alt"></i
        ></a> -->

        <a href="javascript:;" class="btn btn-primary me-1" @click="saveDetails" v-if="hasPermission(permissionsConstants.FILE_UPDATE)">
          <i class="fas fa-save"></i>
        </a>
        <a href="javascript:;" class="btn btn-secondary" @click="closeDetails"
          ><i class="fas fa-window-close"></i
        ></a>
      </div>
    </div>

  <!-- END: User Menu -->
</template>

<script>
import Calendar from 'primevue/calendar';
import InputSwitch from 'primevue/inputswitch';
import Pulse from "@/components/Pulse.vue";
import { filesize } from "filesize";
import Tags from "@/components/tags/Form.vue";
import { mask } from 'vue-the-mask'
import axios from 'axios';
import { mapState, mapActions, mapGetters } from 'vuex';
import { saveAs } from 'file-saver';
import permissionsConstants from '../../constants/permissions';

export default {
  components: { Tags, Pulse, Calendar, InputSwitch },
  directives: { mask },
  computed: {
    ...mapGetters(['hasPermission']),
    ...mapState(['apiEndpoint', 'token']),
    countEmptyStars: function () {
      return 5 - this.rating;
    },
    readableSize: function () {
      return filesize(this.filesize);
    },
    readablecdate: function () {
      const date = new Date(this.cdate);

      return (
        date.toLocaleDateString("pt") +
        " " +
        date.toLocaleTimeString("pt", { hour: "2-digit", minute: "2-digit" })
      );
    },
    readablemdate: function () {
      const date = new Date(this.mdate);

      return (
        date.toLocaleDateString("pt") +
        " " +
        date.toLocaleTimeString("pt", { hour: "2-digit", minute: "2-digit" })
      );
    },
    availableTags: function() {
      const tags = this.$store.getters.tags;
      let availableTags = [];

      tags.forEach((tag) => { availableTags.push(tag.value); });

      return availableTags;
    }
  },
  data() {
    return {
      calendario   : '',
      name         : "",
      validName    : true,
      cdate        : 0,
      mdate        : 0,
      adate        : '',
      reviewed     : false,
      readableadate: '',
      filesize     : 0,
      description  : "",
      loaded       : false,
      thumb_inode  : false,
      thumbUrl     : null,
      rating       : 0,
      editName     : false,
      exif         : '',
      tags         : [],
      showExif     : false,
      folderId     : null
    };
  },
  props: {
    nodeId: {
      type: Number,
      default: 0
    }
  },
  methods: {
    ...mapActions('nodes', ['updateNode', 'deleteNode']),
    async downloadFile() {
      const res = await axios.get(`${this.apiEndpoint}/download/url/${this.nodeId}?x-auth-token=${this.token}`);

      saveAs(res.data.url);
    },
    validateFilename() {
      if (this.name === '') {
        this.validName = false;
      } else {
        this.validName = true;
      }
    },
    formatadate() {
      const d = new Date(this.readableadate);
      this.adate = new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0];
    },
    toggleShowExif() {
      this.showExif = !this.showExif;
    },
    removeTag(i) {
      this.tags.splice(i, 1);
    },
    closeDetails() {
      this.$emit("close");
    },
    toggleEditName() {
      this.editName = !this.editName;
    },
    async fetchDetails() {
      const response = await axios.get(`${this.apiEndpoint}/file/${this.nodeId}`);

      this.name = response.data.name;
      this.folderId = response.data.folderId;
      this.cdate = response.data.cdate;
      this.mdate = response.data.mdate;
      this.adate = response.data.adate;
      this.filesize = response.data.filesize;
      this.description = response.data.description;
      this.rating = parseInt(response.data.rating);
      this.tags = response.data.tags;
      this.exif = response.data.exif;
      this.uploaded = response.data.uploaded;
      this.reviewed = (response.data.reviewed) ? true : false;

      this.loaded = true;

      if (this.adate) {
        this.readableadate = this.adate.split("-").reverse().join("/");
      }

      if (response.data.child) {
        const thumb = response.data.child.find(el => el.level == 'thumb');

        if (thumb) {
          this.thumb_inode = thumb.nodeId;
        }
      }

      //   this.$toast.add({severity:'error', summary: 'Houve um erro ao carregar os dados do arquivo.'}); // TODO: review
    },
    async deleteFile() {
      if (confirm("Tem certeza que deseja remover o arquivo?")) {
        try {
          await axios.delete(`${this.apiEndpoint}/file/${this.nodeId}`);
          this.deleteNode(this.nodeId);
          this.$emit("close");
          this.$toast.add({severity:'success', summary: 'Arquivo removido.', life: 3000});
        } catch {
          this.$toast.add({severity:'error', summary: 'Ocorreu um erro ao remover o arquivo.'});
        }
      }
    },
    async saveDetails() {
      if (this.name === '') {
        this.$toast.add({severity:'error', summary: 'O nome de arquivo não pode ser vazio.'});
        return;
      }

      let data = {
        name       : this.name,
        folderId   : this.folderId,
        rating     : this.rating,
        reviewed   : this.reviewed,
        description: this.description,
        adate      : this.adate
      };

      if (this.tags) {
        data.tags = this.tags;
      }

      try {
        await axios.post(`${this.apiEndpoint}/file/${this.nodeId}`, data);

        this.$emit("close");
        this.updateNode({ nodeId: this.nodeId, name: this.name, reviewed: this.reviewed });

        // this.$toast.add({severity:'success', summary: 'Dados salvos.', life: 3000});
      } catch {
        this.$toast.add({severity:'error', summary: 'Houve um erro ao salvar as alterações.'});

        // .catch((error) => {
        //   switch (error.response.data.code) {
        //     case 10001:
        //       this.$toast.add({severity:'error', summary: 'O nome de arquivo não pode ser vazio.'});
        //     break;

        //     case 10002:
        //       this.$toast.add({severity:'error', summary: 'Já existe um arquivo com o mesmo nome.'});
        //     break;

        //     default:
        //       this.$toast.add({severity:'error', summary: 'Houve um erro ao salvar as alterações.'});
        //     break;
        //   }
        // });
      }

    },
    markRating(e) {
      const position = e.currentTarget.getAttribute("position");

      if (position == this.rating) {
        this.rating = 0;
      } else {
        this.rating = parseInt(position);
      }
    },
    dismissListener(event) {
      if (event.code == 'Escape') {
        this.$emit('close');
      }
    }
  },
  emits: ['close'],
  async mounted() {
    try {
      this.fetchDetails();
      await this.$store.dispatch('tags/refreshTags');
      window.addEventListener('keydown', this.dismissListener);
    } catch {
      this.$toast.add({severity:'error', summary: 'Ocorreu um erro ao ler os dados do arquivo.'});
      this.$emit('close');
    }
  },
  watch: {
    async thumb_inode(newValue) {
      const token = this.$store.getters.token;
      const api_endpoint = this.$store.getters.api_endpoint;

      const res = await axios.get(`${api_endpoint}/download/url/${newValue}?x-auth-token=${token}`);

      this.thumbUrl = res.data.url;
    }
  },
  unmounted() {
    window.removeEventListener("keydown", this.dismissListener);
  },
  created() {
    this.permissionsConstants = permissionsConstants;
  }
};
</script>

<style scoped>
.list-group-item {
  padding-left: 0;
}
textarea.form-control:not(.form-control-lg):not(.form-control-sm) {
  height: 100px;
}

.fa-star:hover {
  color: #FFBF61;
}

.full-star {
  color: #FFBF61;
  /* color: yellow; */
}

.form_filename {
  border: 0;
  border-bottom: 1px solid;
}
.btn-controls i {
  color: white;
}
.input-line  {
  border: none;
  border-bottom: solid 1px;
  width: 120px;
}
</style>