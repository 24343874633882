<template>
  <teleport to="body">
    <Sidebar v-model:visible="showDetails" class="p-sidebar-md" position="right" :showCloseIcon="false" :autoZIndex="false">
      <file-details :nodeId="nodeId" @close="hideDetails" />
    </Sidebar>
  </teleport>


  <div class="card app-file-list" :class="{ selected: this.file.selected }">
    <div class="app-file-icon">
      <img :src="thumbUrl" class="thumb" loading="lazy" :class="blurClass" @dblclick="openPreview" v-if="fetchSetting('viewMode') === 'thumb' && thumbUrl">
      <i class="far text-success" :class="[fileicon, blurClass]" @dblclick="openPreview" v-else></i>
      <div class="position-absolute top-0 left-05">
        <input type="checkbox" :checked="file.selected" @click="toggleSelected" />
      </div>
      <div class="dropdown position-absolute top-0 right-0 me-3">
        <a
          href="#"
          class="btn btn-outline-light btn-sm"
          data-bs-toggle="dropdown"
        >
          <i class="fa fa-ellipsis-h"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <a href="javascript:;" class="dropdown-item" @click="details"><font-awesome-icon :icon="['fas', 'info-circle']" /> <span class="px-2">Detalhes</span></a>
          <!-- <a href="#" class="dropdown-item">Compartilhar</a> -->
          <a href="javascript:;" class="dropdown-item" @click="downloadFile"><font-awesome-icon :icon="['fas', 'download']" /> <span class="px-2">Download</span></a>
          <move-file v-if="hasPermission(permissionsConstants.FILE_UPDATE)" :nodeId="nodeId" />
          <a href="javascript:;" class="dropdown-item" @click="rename" v-if="hasPermission(permissionsConstants.FILE_UPDATE)"><font-awesome-icon :icon="['fas', 'pen']" /> <span class="px-2">Renomear</span></a>
          <a href="javascript:;" class="dropdown-item" @click="deleteFile" v-if="hasPermission(permissionsConstants.FILE_DELETE)"><font-awesome-icon :icon="['far', 'trash-alt']" /> <span class="px-2">Remover</span></a>
        </div>
      </div>
    </div>
    <div class="p-2 small">
      <div> {{ file['name'] }}</div>
      <div class="text-muted">{{ readableSize }}</div>
      <i class="fas fa-check" v-if="file['reviewed']" title="Revisado"></i>
    </div>

    <file-preview :show="showPreview" :nodeId="nodeId" @close="closePreview" />
  </div>
</template>

<script>
import { filesize } from "filesize";
import FilePreview from '@/components/node/FilePreview.vue';
import FileDetails from "@/components/sidebars/FileDetails.vue";
import Sidebar from 'primevue/sidebar';
import MoveFile from './MoveFile.vue';
import axios from 'axios';
import permissionsConstants from '../../constants/permissions';
import { saveAs } from 'file-saver';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  components: { FilePreview, FileDetails, Sidebar, MoveFile },
  data() {
    return {
      visibleLeft: false,
      showPreview: false,
      showDetails: false,
    }
  },
  computed: {
    ...mapState(['apiEndpoint', 'token']), 
    ...mapGetters(['hasPermission', 'fetchSetting']),
    ...mapGetters('nodes', ['getNode']),
    file() {
      return this.getNode(this.nodeId);
    },
    blurClass: function() {
      if (this.uploaded == 0) {
        return 'blurred';
      } else {
        return '';
      }
    },
    fileicon: function () {
      let icon = "fa-file";

      switch (this.file.mimetype) {
        case "excel":
          icon = "fa-file-excel";
          break;

        case "text/plain":
          icon = "fa-file-alt";
          break;

        case "powerpoint":
          icon = "fa-file-powerpoint";
          break;

        case "word":
          icon = "fa-file-word";
          break;

        case "zip":
          icon = "fa-file-archive";
          break;

        case "application/pdf":
          icon = "fa-file-pdf";
          break;

        case "image/jpeg":
        case "image/png":
        case "image/tiff":
          icon = "fa-file-image";
          break;

        case "video/mp4":
          icon = "fa-file-video";
          break;

        case "mp3":
        case "audio/mpeg":
          icon = "fa-file-audio";
          break;
      }

      return icon;
    },
    thumbUrl() {
      console.log(this.file);
      if (this.file['thumbNodeId']) {
        return `${this.apiEndpoint}/download/${this.file['thumbNodeId']}?x-auth-token=${this.token}`;
      } else {
        return false;
      }
    },
    readableSize() {
      return filesize(this.file['filesize']);
    },
    previewable() {
      const types = ["text/plain", 'image/jpeg', 'image/png', 'image/tiff', 'audio/mp3', 'video/mp4', 'application/pdf', 'audio/mpeg'];

      if (types.indexOf(this.file['mimetype']) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions('nodes', ['addSelectedItem', 'removeSelectedItem', 'updateNode', 'deleteNode']),
    async downloadFile() {
      const res = await axios.get(`${this.apiEndpoint}/download/url/${this.nodeId}?x-auth-token=${this.token}`);

      saveAs(res.data.url);
    },
    openFolder() {
      this.$router.push('/files/' + this.id_folder);
    },
    details() {
      this.showDetails = true;
    },
    hideDetails() {
      this.showDetails = false;
    },
    openPreview() {
      if (!this.file['uploaded']) {
        this.$toast.add({
          severity: "warn",
          summary : "O arquivo está em processamento.",
          life    : 3000,
        });
      } else if (this.previewable) {
        this.showPreview = true;
      }
    },
    closePreview() {
      this.showPreview = false;
    },
    async rename() {
      const newFilename = prompt('Entre com o novo nome de arquivo: ', this.filename);

      if (!newFilename) {
        this.$toast.add({severity:'error', summary: 'O arquivo não pode ter nome vazio.'});
        return;
      }

      if (this.filename != newFilename) {
        const body = {
          name: newFilename,
          folderId: this.folderId
        }

        try {
          await axios.post(`${this.apiEndpoint}/file/${this.nodeId}`, body);

          this.filename = newFilename;
          this.updateNode({ nodeId: this.nodeId, name: newFilename });
        } catch {
          //   if (error.response.data.code === 10002) {
          //     this.$toast.add({severity:'error', summary: 'Já existe um arquivo com o mesmo nome.'});
          //   } else {
          //     this.$toast.add({severity:'error', summary: 'Ocorreu um erro ao renomear o arquivo.'});
          //   }
        }
      }
    },
    async deleteFile() {
      if (confirm("Tem certeza que deseja remover o arquivo?")) {
        try {
          await axios.delete(`${this.apiEndpoint}/file/${this.nodeId}`);
          this.deleteNode(this.nodeId);
          this.$emit("delete");
          this.$toast.add({severity:'success', summary: 'Arquivo removido.', life: 3000});
        } catch {
          this.$toast.add({severity:'error', summary: 'Ocorreu um erro ao remover o arquivo.'});
        }
      }
    },
    toggleSelected() {
      if (this.file.selected) {
        this.removeSelectedItem(this.nodeId);
      } else {
        this.addSelectedItem(this.nodeId);
      }
    }
  },
  props: {
    nodeId: {
      type: Number,
      default: 0,
      required: true
    },
  },
  emits: ['update:name', 'delete'],
  async created() {
    this.permissionsConstants = permissionsConstants;
  }
};
</script>

<style scoped>
i.far {
  cursor: pointer;
}
img.thumb {
  cursor: pointer;
  max-width: 150px;
  max-height: 150px;
}
.blurred {
  filter: blur(2px) grayscale(100%);
}
.text-muted {
  float: left;
}
.fa-check {
  float: right;
  color: green;
}
.selected {
  border: solid 1px #6699cc;
}
</style>
