<template>
  <div class="flex w-full h-screen items-center justify-center">
    <div
      class="p-12 bg-gray-100 border border-gray-300 cursor-pointer text-center"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        multiple
        name="fields[assetsFieldHandle][]"
        id="assetsFieldHandle"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        @change="onChange"
        ref="file"
        style="display: none"
      />

      <label for="assetsFieldHandle" class="block" style="margin: 15px">
        <div class="dragndrop">
          <i class="fas fa-arrow-circle-down" style="font-size: 4em"></i> <br />
          Arraste seus arquivos aqui.
        </div>
      </label>

      <ul class="mt-4" v-if="filelist.length" v-cloak>
        <li class="text-sm p-1" v-for="(file, i) in filelist" :key="i">
          {{ file.name }}
          <a @click="remove(i)" v-if="!uploading">
            <font-awesome-icon
              class="width-15 height-15 mr-2"
              :icon="['far', 'trash-alt']"
            />
          </a>

          <i class="far fa-clock" v-if="uploading && uploadProgress[i].status == 'uploading'"></i>
          <i class="fas fa-exclamation-circle" style="color: red" v-if="uploadProgress[i].status == 'error'"></i>
          <i class="fas fa-check-circle" style="color: green;" v-if="uploadProgress[i].status == 'done'"></i>
          <br />
          <progress id="file" :max="uploadProgress[i].max" :value="uploadProgress[i].value" v-if="uploadProgress[i].status != 'done'" />

          <!-- <button class="btn btn-primary ml-2" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">remove</button> -->
        </li>
      </ul>
    </div>

    <div v-if="filelist.length > 0">
      <div class="mb-3 mt-3">
        <Calendar v-model="readableadate" dateFormat="dd/mm/yy" placeholder="Data atribuída" @date-select="formatadate" style="width: 100%" />
      </div>

      <div class="mb-3">
        <Textarea class="form-control" rows="3" placeholder="Descrição" v-model="description" :autoResize="true" />
      </div>

      <div class="mb-3">
        <tags v-model:tags="tags" />
      </div>

      <div class="text-center">
        <button
          class="btn btn-secondary file-upload-btn me-2"
          @click="doUpload"
          :disabled="uploading"
          v-if="filelist.length > 0"
        >
          Upload
        </button>
        <button
          class="btn btn-primary file-upload-btn"
          @click="clearList"
          v-if="filelist.length > 0"
        >
          Limpar
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import Tags from "@/components/tags/Form.vue";
import { mapGetters } from 'vuex'

export default {
  components: { Tags, Textarea, Calendar },
  data() {
    return {
      filelist      : [],      // Store our uploaded files
      uploadProgress: [],
      uploading     : false,
      tags          : [],
      description   : null,
      rating        : 0,
      adate         : null,
      readableadate : ''
    };
  },
  computed: {
    ...mapGetters(['nodeId', 'apiEndpoint']),
    extOps() {
      // Detects if there's any extended options
      if ((this.tags.length > 0) || (this.description) || (this.adate)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    formatadate() {
      const d = new Date(this.readableadate);
      this.adate = new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0];
    },
    onChange() {
      for (let file of this.$refs.file.files) {
        const progress = {
          max: 100,
          value: 0,
          status: 'waiting'
        };
        this.uploadProgress = [...this.uploadProgress, progress];

        this.filelist = [...this.filelist, file];
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.uploadProgress.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    // Uploads the file in chunks
    async uploadFile(i, chunk, tempFilename) {
      const chunkSize    = 8388608;                           // Chunk size 4Mb
      const file         = this.filelist[i];
      const folderId     = this.nodeId;

      let   reader = new FileReader();
      const ref    = this;              // Use this (Vue) reference

      const start      = chunk * chunkSize;                 // Defines the start position to split
      const next_slice = start + chunkSize;                 // Defines the end position to split
      const blob       = file.slice(start, next_slice);     // Split the chunk
      const fileSize   = file.size;
      const chunks     = Math.ceil(file.size / chunkSize);  // Calculate the total number of chunks

      reader.onloadend = function( event ) {
        if ( event.target.readyState !== FileReader.DONE ) {
          return;
        }

        axios
        .request({
          method: "put",
          url: ref.apiEndpoint + "/file",
          data: event.target.result,
          headers: {
            "Content-Type": "application/octet-stream",
            "X-Folder": folderId,
            "X-Filename": file.name,
            "X-Chunk": chunk,
            "X-Chunks": chunks,
            "X-Tempfilename": tempFilename
          },
          onUploadProgress: (p) => {
            ref.uploadProgress[i].max = fileSize;
            ref.uploadProgress[i].value = p.loaded + (chunk * chunkSize);
            ref.uploadProgress[i].status = 'uploading';
          }
        })
        .then((resp) => {
          ref.uploadProgress[i].max = fileSize;
          ref.uploadProgress[i].value = chunk * chunkSize;
          ref.uploadProgress[i].status = 'uploading';

          if (next_slice < file.size) {
            const tempFilename = resp.data.tempFilename;
            chunk++; // Set the next chunk and upload again
            ref.uploadFile(i, chunk, tempFilename);
          } else {
            // End of uploading for this file
            const idFile = resp.data.nodeId;
            ref.uploadProgress[i].status = 'done';
            ref.updateFileData(idFile);
          }
        })
        .catch(() => {
          ref.uploadProgress[i].status = 'error';
        })
      }

      reader.readAsArrayBuffer( blob );
    },
    async doUpload() {
      this.uploading = true;

      for (let i = 0; i < this.filelist.length; i++) {
        if (this.uploadProgress[i].status != 'done') {

          const fileSize = this.filelist[i].size;

          // Check if filesize is allowed in system's quota
          try {
            await axios.get(`${this.apiEndpoint}/file/checkQuota?filesize=${fileSize}`);

            await this.uploadFile(i, 0);
          } catch {
            this.uploadProgress[i].status = 'error';
            this.$toast.add({severity:'error', summary: 'O arquivo extrapola o limite disponível de espaço de armazenamento.'});
          }
        }
      }
    },
    clearList() {
      this.filelist = [];
      this.uploadProgress = [];
      this.uploading = false;
    },
    async updateFileData(idFile) {
      let data = {
        id_folder  : this.inode,
        rating     : this.rating,
        description: this.description
      };

      if (this.readableadate) {
        data.adate = new Date(this.readableadate).toLocaleDateString('fr-CA');
      } else {
        data.adate = '';
      }

      if (this.tags) {
        data.tags = this.tags;
        data.append_tags = 1; // Do not overwrite previous tags for that file (inherited from parent folder)
      }

      await axios.post(`${this.apiEndpoint}/file/${idFile}`, data);
    }
  }
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

.bg-green-300 {
  background-color: #9ae6b4;
}
.bg-gray-100 {
  background-color: #f7fafc;
}
.border-gray-300 {
  border-color: #e2e8f0;
}
.border {
  border-width: 1px;
}
label {
  font-weight: bold;
  display: block;
}
</style>