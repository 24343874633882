<template>
  <Dialog
    @hide="hide"
    @show="showDialogTrigger"
    style="width: 450px"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw' }"
  >
    <template #header>
      <h3 v-if="type == 'move'">Mover para</h3>
      <h3 v-if="type == 'copy'">Copiar para</h3>
    </template>

    <p v-if="type == 'move'">Escolha uma pasta para mover:</p>
    <p v-if="type == 'copy'">Escolha uma pasta para copiar:</p>

    <Tree
      :value="nodes"
      @nodeExpand="onNodeExpand"
      @nodeSelect="selected"
      :loading="loading"
      selectionMode="single"
    ></Tree>

    <p v-if="sameFolder" class="error">
      A pasta de origem e de destino não podem ser as mesmas.
    </p>

    <template #footer>
      <input
        type="button"
        class="btn btn-primary"
        :value="btnAction"
        @click="submit"
        :disabled="sameFolder"
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Tree from "primevue/tree";
import { mapState } from "vuex";
import nodesApi from '../../api/nodes';

export default {
  components: { Dialog, Tree },
  computed: {
    ...mapState(['apiEndpoint', 'nodeId']),
    btnAction() {
      if (this.type == "move") {
        return "Mover";
      } else {
        return "Copiar";
      }
    },
    sameFolder() {
      return this.selectedKey == this.nodeId;
    },
  },
  data() {
    return {
      isVisible: true,
      loading: false,
      selectedKey: null,
      nodes: [
        {
          key: 0,
          label: "/",
          icon: "pi pi-folder",
          leaf: false,
        },
      ],
    };
  },
  props: {
    show: {
      type: Boolean
    },
    type: {
      type: String,
      default: 'move'
    }
  },
  emits: ["close"],
  methods: {
    submit() {
      this.$emit("submit", this.selectedKey);
    },
    hide() {
      console.log('close');
      this.$emit("close");
    },
    append(structure, key, child) {
      for (let i = 0; i < structure.length; i++) {
        if (structure[i].children) {
          this.append(structure[i].children, key, child);
        } else {
          if (structure[i].key == key) {
            structure[i].children = child;
          }
        }
      }
    },
    async onNodeExpand(node) {
      if (!node.children) {
        this.loading = true;
        const nodeId = node.key;

        try {
          const tree = await nodesApi.getDirTree(nodeId);
  
          if (tree.length > 0) {
            let child = [];
  
            for (let i = 0; i < tree.length; i++) {
              let node = {
                key: parseInt(tree[i].nodeId),
                label: tree[i].name,
                icon: "pi pi-folder",
                leaf: false,
              };
  
              child.push(node);
            }
  
            this.append(this.nodes, node.key, child);
          } else {
            node.leaf = true;
          }
        } catch {
          this.$toast.add({severity:'error', summary: 'Houve um erro ao carregar os dados da pasta.', life: 3000});
        }


        this.loading = false;
      }
    },
    selected(node) {
      this.selectedKey = node.key;
    },
  },
  watch: {
    show(newValue) {
      this.showDialog = newValue;
    }
  }
};
</script>
