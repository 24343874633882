<template>
  <DirTree v-model:visible="showDirTree" @action="move" />

  <div class="card app-file-list" >
    <Skeleton width="100%" height="9rem"></Skeleton>
  </div>
</template>

<script>
import Skeleton from 'primevue/skeleton';

export default {
  components: { Skeleton }
};
</script>
