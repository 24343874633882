<template>
  <div class="page-header">
    <Breadcrumb :home="home" :model="items" />
  </div>
</template>

<script>
import Breadcrumb from 'primevue/breadcrumb';
import { mapState } from 'vuex';

export default {
  components: { Breadcrumb },
  computed: {
    ...mapState(['path'])
  },
  data() {
    return {
      home: { icon: 'pi pi-home', to: '/files/0' },
      items: []
    }
  },
  watch: {
    path(newVal) {
      this.items = newVal.map((el) => ({ label: el.name, to: `/files/${el.nodeId}` }));
    }
  }
};
</script>
