<template>
  <div class="app -content-overlay"></div>

  <DirTree v-model:visible="showDirTree" @action="move" />

  <div class="app-action">
    <div class="action-left">
      <button
        class="btn btn-outline-light me-2"
        type="button"
        id="dropdownMenuButton1"
        aria-expanded="false"
        @click="$emit('reload')"
        v-if="allowReload"
        :disabled="searchMode"
      >
        <font-awesome-icon class="me-1" :icon="['fas', 'redo']" />
      </button>

      <div class="dropdown">
        <button
          class="btn btn-outline-light"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="fas fa-ellipsis-v"></i>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <a class="dropdown-item" :class="{ disabled: !hasFiles, grey: !hasFiles }" @click="selectDeselectAllFiles">Marcar/Desmarcar todos</a>
            <a class="dropdown-item" :class="{ disabled: !hasSelectedItems, grey: !hasSelectedItems }" @click="showEditTagsDialog">Editar tags</a>
            <a class="dropdown-item" :class="{ disabled: !hasSelectedItems, grey: !hasSelectedItems }" @click="toggleMove">Mover</a>
            <a class="dropdown-item" :class="{ disabled: !hasSelectedItems, grey: !hasSelectedItems }" @click="batchReviewFiles">Marcar revisado</a>
          </li>
          <li><hr class="dropdown-divider" /></li>
          <li>
            <a class="dropdown-item disabled title" href="#" aria-disabled="true"
              >Pré-visualização</a
            >
          </li>
          <li>
            <toggle-view-mode />
          </li>
          <li><hr class="dropdown-divider" /></li>
          <li>
            <a class="dropdown-item disabled title" href="#" aria-disabled="true"
              >Ordenação</a
            >
          </li>
          <li>
            <a class="dropdown-item" @click="changeOrder('name')" href="#"
              >Nome</a
            >
          </li>
          <li>
            <a class="dropdown-item" @click="changeOrder('date')" href="#"
              >Data</a
            >
          </li>
          <li>
            <a class="dropdown-item" @click="changeOrder('size')" href="#"
              >Tamanho</a
            >
          </li>
          <li>
            <a class="dropdown-item" @click="changeOrder('rating')" href="#"
              >Avaliação</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="action-right">
      <form class="d-flex me-3" @submit.prevent="searchFiles">
        <a href="#" class="app-sidebar-menu-button btn btn-outline-light">
          <i data-feather="menu"></i>
        </a>

        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
              <i class="fa fa-search"></i>
          </span>
          <InputText placeholder="Busque por nome de arquivo ou tag." v-model="searchQuery" />
          <span class="p-inputgroup-addon" @click="toggleAdvancedSearch">
            <i class="fas fa-ellipsis-v"></i>
          </span>
        </div>
      </form>
    </div>
  </div>

  <Card class="mb-4" v-show="advancedSearchShow">
    <template #content>
      <form @submit.prevent="submitAdvancedSearch">
        <div class="row mb-4">
          <div class="col-3">
            <InputText placeholder="Nome de arquivo" v-model="advancedSearch.filename" />
          </div>

          <div class="col-3">
            <InputText placeholder="Descrição" v-model="advancedSearch.description" class="p-inputtext-filled" />
          </div>

          <div class="col">
            <tags v-model:tags="advancedSearch.tags" />
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-3">
            <Dropdown v-model="advancedSearch.ratingMin" :options="ratings" optionLabel="name" optionValue="value" placeholder="Avaliação (min)" />
          </div>

          <div class="col-3">
            <Dropdown v-model="advancedSearch.ratingMax" :options="ratings" optionLabel="name" optionValue="value" placeholder="Avaliação (máx)" />
          </div>

          <div class="col-3">
            <Dropdown v-model="advancedSearch.reviewed" :options="reviewed" optionLabel="name" optionValue="value" placeholder="Revisão" />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <Button type="submit" label="Buscar" icon="pi pi-search" />
          </div>
        </div>
      </form>
    </template>
  </Card>

  <batch-edit-tags :show="showEditTags" @hide="hideEditTagsDialog" />
</template>

<script>
import axios from 'axios';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
// import Calendar from 'primevue/calendar';
import DirTree from '@/components/node/DirTree.vue';
import ToggleViewMode from './ToggleViewMode.vue';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Tags from "@/components/tags/Form.vue";
import BatchEditTags from '@/components/node/BatchEditTags.vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import dateHelper from '../../helpers/date';

export default {
  components: { InputText, Dropdown, Card, Tags, Button, BatchEditTags, DirTree, ToggleViewMode },
  props: {
    allowReload: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      advancedSearchShow: false,
      searchQuery: '',
      ratings: [
        {
          name: 'N/A',
          value: null,
        }, {
          name: '0',
          value: 0
        }, {
          name: 1,
          value: 1
        }, {
          name: 2,
          value: 2
        }, {
          name: 3,
          value: 3
        }, {
          name: 4,
          value: 4
        }, {
          name: 5,
          value: 5
        }
      ],
      reviewed: [
        {
          name: 'N/A',
          value: null
        }, {
          name: 'Revisado',
          value: 1
        }, {
          name: 'Não revisado',
          value: 0
        }
      ],
      advancedSearch: {
        filename: null,
        description: null,
        adateMin: null,
        adateMax: null,
        ratingMax: null,
        ratingMin: null,
        reviewed: null,
        tags: []
      },
      showEditTags: false,
      showDirTree: false
    }
  },
  computed: {
    ...mapGetters(['searchMode', 'apiEndpoint']),
    ...mapGetters('nodes', ['hasSelectedItems', 'selectedNodes', 'hasFiles']),
    ...mapState(['settings']),
    ...mapGetters(['fetchSetting']),
  },
  methods: {
    ...mapActions('nodes', ['toggleReviewed', 'deleteNode', 'updateNode', 'selectDeselectAllFiles']),
    toggleMove() {
      this.showDirTree = !this.showDirTree;
    },
    async toggleViewMode() {

    },
    async batchReviewFiles() {
      try {
        const body = {
          nodes: this.selectedNodes
        }
        
        const res = await axios.post(`${this.apiEndpoint}/file/batchReviewFiles`, body);
        const reviewed = res.data.reviewed;
        this.selectedNodes.forEach((node) => {
          this.updateNode({ nodeId: node, reviewed });
        });
        
      } catch {
        this.$toast.add({severity:'error', summary: 'Houve um erro ao revisar os arquivos.'});
      }
    },
    async move(newFolderId) {
      try {
        const body = {
          nodes: this.selectedNodes,
          nodeId: newFolderId
        }
        
        await axios.post(`${this.apiEndpoint}/file/batchMoveFiles`, body);
        this.showDirTree = false;

        this.selectedNodes.forEach((node) => {
          this.deleteNode(node);
        });
      } catch {
        this.$toast.add({severity:'error', summary: 'Houve um erro ao mover os arquivos.'});
      }
    },
    searchFiles() {
      this.$router.push('/search?query=' + this.searchQuery);
    },
    submitAdvancedSearch() {
      let query = '';

      query += (this.advancedSearch.filename) ? `filename=${this.advancedSearch.filename}&` : '';
      query += (this.advancedSearch.description) ? `description=${this.advancedSearch.description}&` : '';
      query += (this.advancedSearch.ratingMin) ? `ratingMin=${this.advancedSearch.ratingMin}&` : '';
      query += (this.advancedSearch.ratingMax) ? `ratingMax=${this.advancedSearch.ratingMax}&` : '';
      query += (this.advancedSearch.reviewed) ? `reviewed=${this.advancedSearch.reviewed}&` : '';
      query += (this.advancedSearch.adateMin) ? `adateMin=${dateHelper.formatDate(this.advancedSearch.adateMin)}&` : '';
      query += (this.advancedSearch.adateMax) ? `adateMax=${dateHelper.formatDate(this.advancedSearch.adateMax)}&` : '';
      query += (this.advancedSearch.tags.length > 0) ? `tags=${JSON.stringify(this.advancedSearch.tags)}&` : '';

      this.$router.push(`/advancedSearch?${query}`);
    },
    toggleAdvancedSearch() {
      this.advancedSearchShow = !this.advancedSearchShow;
    },
    showEditTagsDialog() {
      this.showEditTags = true;
    },
    hideEditTagsDialog() {
      this.showEditTags = false;
    }
  },
  mounted() {
    const query = this.$route.query;

    if (Object.keys(query).length > 0) {
      this.searchQuery = query.query;
    }
  }
}
</script>

<style scoped>
.grey {
  color: grey !important;
}

.title {
  font-weight: bold;
}
</style>