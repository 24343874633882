<template>
  <div class="card-body">
    <h6 class="mb-2">Status do armazenamento</h6>
    <div class="d-flex align-items-center">
      <div class="me-3">
        <font-awesome-icon
          class="width-30 height-30"
          :icon="['fas', 'hdd']"
        />
      </div>
      <div class="flex-grow-1">
        <div class="progress" style="height: 10px">
          <div
            class="progress-bar progress-bar-striped"
            role="progressbar"
            :style="diskQuotaUsagePercentageStyle"
          ></div>
        </div>
        <div class="line-height-12 small text-muted mt-2">
          {{ formatSize(diskUsage) || 0 }} de {{ formatSize(diskQuota) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { filesize } from "filesize";

export default {
  computed: {
    ...mapState(['diskUsage', 'diskQuota']),
    diskQuotaUsagePercentageStyle() {
      return 'width: ' + Math.round(this.diskUsage / this.diskQuota * 100) + '%';
    },
  },
  methods: {
    ...mapActions(['getDiskUsage']),
    formatSize(size) {
      if (!size) {
        return null;
      }

      return filesize(size);
    },
  },
  created() {
    this.getDiskUsage();
  }
}
</script>