<template>
  <div class="card-body">
    <button
      class="btn btn-outline-primary me-2 btn-block"
      type="button"
      aria-expanded="false"
      @click="mkdir"
      :disabled="searchMode"
    >
      <font-awesome-icon :icon="['fas', 'folder-plus']" />
      <span class="ms-2">
        Criar pasta
      </span>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState(['nodeId', 'searchMode']),
  },
  methods: {
    ...mapActions('nodes', ['newDir']),
    async mkdir() {
      const newdir = prompt("Entre com o nome da nova pasta:");

      if (newdir) {
        const payload = {
          name: newdir,
          folderId: this.nodeId
        };

        try {
          this.newDir(payload);
        } catch (err) {
          this.$toast.add({severity:'error', summary: 'Houve um erro ao criar a pasta.'});
        }
      }
    }
  }
}
</script>
