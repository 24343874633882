<template>
    <div class="text-center mt-5" v-if="!loaded">
      <pulse />
    </div>
    <div
      class="pt-4 text-center"
      data-backround-image="@/assets/media/image/image1.jpg"
      v-if="loaded"
    >
      <figure class="mb-3 border-0">
        <i class="far text-success fa-folder" style="font-size: 4em;"></i>
      </figure>
      <h5
        class="d-flex align-items-center justify-content-center"
        v-if="!editName"
      >
        {{ name }} <i class="fas fa-pen ms-2" @click="toggleEditName"></i>
      </h5>
      <div v-if="editName">
        <input type="text" v-model.trim="name" class="form_filename" @keyup.esc="toggleEditName" @blur="validateFilename" :class="{error: !validName}" />
      </div>
      <div>
        Quantidade de itens: <strong>{{ count_child }}</strong>
      </div>
    </div>
    <div class="card mb-0 card-body shadow-none" v-if="loaded">
      <div class="mb-4">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">Criado em: {{ readablecdate }}</li>
          <li class="list-group-item">Atualizado em: {{ readablemdate }}</li>
          <li class="list-group-item">Data atribuída: <Calendar v-model="readableadate" dateFormat="dd/mm/yy" @date-select="formatadate" /></li>
        </ul>
      </div>
      <div class="mb-4">
        <h6>Descrição</h6>
        <p class="text-muted mb-0">
          <textarea class="form-control" rows="3" v-model="description" />
        </p>
      </div>
      <div class="mb-4">
        <h6>Tags</h6>

        <span class="p-fluid">
          <tags v-model:tags="tags" />
        </span>

      </div>
      <div class="mb-4 btn-controls">
        <a href="#" class="btn btn-primary me-1" @click="deleteFolder" v-if="hasPermission(permissionsConstants.FOLDER_DELETE)"
          ><i class="fas fa-trash-alt"></i
        ></a>
        
        <a href="#" class="btn btn-primary me-3" v-if="false"
          ><i class="fas fa-share-alt"></i
        ></a>

        <a href="#" class="btn btn-primary me-1" @click="saveDetails" v-if="hasPermission(permissionsConstants.FOLDER_UPDATE)">
          <i class="fas fa-save"></i>
        </a>
        <a href="#" class="btn btn-secondary" @click="closeDetails"
          ><i class="fas fa-window-close"></i
        ></a>
      </div>
    </div>

  <!-- END: User Menu -->
</template>

<script>
import Pulse from "@/components/Pulse.vue";
import Tags from "@/components/tags/Form.vue";
import {mask} from 'vue-the-mask'
import Calendar from 'primevue/calendar';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import permissionsConstants from '../../constants/permissions';

export default {
  components: { Tags, Pulse, Calendar },
  directives: { mask },
  computed: {
    ...mapGetters(['hasPermission']),
    readablecdate: function () {
      const date = new Date(this.cdate);

      return (
        date.toLocaleDateString("pt") +
        " " +
        date.toLocaleTimeString("pt", { hour: "2-digit", minute: "2-digit" })
      );
    },
    readablemdate: function () {
      const date = new Date(this.mdate);

      return (
        date.toLocaleDateString("pt") +
        " " +
        date.toLocaleTimeString("pt", { hour: "2-digit", minute: "2-digit" })
      );
    }
  },
  data() {
    return {
      name         : "",
      validName    : true,
      cdate        : 0,
      mdate        : 0,
      adate        : '',
      readableadate: '',
      count_child  : 0,
      description  : "",
      loaded       : false,
      thumb_inode  : false,
      editName     : false,
      tags         : [],
      id_folder    : null
    };
  },
  props: ["nodeId"],
  methods: {
    ...mapActions('nodes', ['deleteNode']),
    ...mapActions('nodes', ['updateNode']),
    validateFilename() {
      if (this.name === '') {
        this.validName = false;
      } else {
        this.validName = true;
      }
    },
    formatadate() {
      const d = new Date(this.readableadate);
      this.adate = new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0];
    },
    removeTag(i) {
      this.tags.splice(i, 1);
    },
    closeDetails() {
      this.$emit("close");
    },
    toggleEditName() {
      this.editName = !this.editName;
    },
    async fetchDetails() {
      const api_endpoint = this.$store.getters.api_endpoint;

      try {
        const response = await axios.get(`${api_endpoint}/dir/details/${this.nodeId}`);

        this.name        = response.data.name;
        this.folderId    = response.data.folderId;
        this.cdate       = response.data.cdate;
        this.mdate       = response.data.mdate;
        this.adate       = response.data.adate;
        this.description = response.data.description;
        this.tags        = response.data.tags;

        if (this.adate) {
          this.readableadate = this.adate.split("-").reverse().join("/");
        }

        if (response.data.thumb) {
          this.thumb_inode = response.data.thumb.inode;
        }

        this.loaded = true;
      } catch {
        this.$toast.add({severity:'error', summary: 'Houve um erro ao carregar os dados da pasta.'}); // TODO: review
      }
    },
    async saveDetails() {
      const api_endpoint = this.$store.getters.api_endpoint;

      if (this.name === '') {
        this.$toast.add({severity:'error', summary: 'O nome da pasta não pode ser vazio.'});
      } else {
        let payload = {
          name       : this.name,
          description: this.description
        };

        if (this.readableadate) {
          payload.adate = new Date(this.readableadate).toLocaleDateString('fr-CA');
        } else {
          payload.adate = '';
        }

        if (this.tags) {
          payload.tags = this.tags;
        }

        await axios.post(`${api_endpoint}/dir/${this.nodeId}`, payload);
        this.updateNode({ nodeId: this.nodeId, name: this.name });
        this.$emit('close');
      }
    },
    dismissListener(event) {
      if (event.code == 'Escape') {
        this.$emit('close');
      }
    },
    async deleteFolder() {
      if (confirm('Tem certeza que deseja remover esta pasta?')) {
        try {
          this.deleteNode(this.nodeId);
          this.$toast.add({severity: 'success', summary: 'Pasta removida.', life: 3000});
          this.$emit('close');
        } catch (e) {
          switch(e.response.data.errCode) {
            case 'NOEMP':
              this.$toast.add({severity: 'error', summary: 'Pasta não está vazia.'});
              break;
              
            default:
              this.$toast.add({severity: 'error', summary: 'Houve um erro ao remover a pasta.'});
              break;
          }
        }
      }
    },
  },
  emits: ["close", "updateFolderName"],
  mounted() {
    this.fetchDetails();
    window.addEventListener('keydown', this.dismissListener);
  },
  unmounted() {
    window.removeEventListener("keydown", this.dismissListener);
  },
  created() {
    this.permissionsConstants = permissionsConstants;
  }
};
</script>

<style scoped>
.list-group-item {
  padding-left: 0;
}
textarea.form-control:not(.form-control-lg):not(.form-control-sm) {
  height: 100px;
}

.fa-star:hover {
  color: #FFBF61;
}

.full-star {
  color: #FFBF61;
  /* color: yellow; */
}

.form_filename {
  border: 0;
  border-bottom: 1px solid;
}
.btn-controls i {
  color: white;
}
.input-line  {
  border: none;
  border-bottom: solid 1px;
  width: 120px;
}
</style>