<template>
  <teleport to="body">
    <Sidebar v-model:visible="showDetails" class="p-sidebar-md" position="right" :showCloseIcon="false" :autoZIndex="false">
      <folder-details :nodeId="nodeId" @close="hideDetails" />
    </Sidebar>
  </teleport>

  <DirTree v-model:visible="showDirTree" @action="move" />

  <div class="card app-file-list" @dblclick="cd">
    <div class="app-file-icon">
      <!-- <i class="fa fa-file-pdf-o text-danger"></i> -->
      <font-awesome-icon :icon="['far', 'folder']" />
      <div class="dropdown position-absolute top-0 right-0 me-3" v-if="!up">
        <a
          href="#"
          class="btn btn-outline-light btn-sm"
          data-bs-toggle="dropdown"
        >
          <i class="fa fa-ellipsis-h"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <a href="javascript:;" class="dropdown-item" @click="details"><font-awesome-icon :icon="['fas', 'info-circle']" /> <span class="px-2">Detalhes</span></a>
          <a href="javascript:;" class="dropdown-item" @click="toggleMove" v-if="hasPermission(permissionsConstants.FOLDER_UPDATE)"><font-awesome-icon :icon="['fas', 'copy']" /> <span class="px-2">Mover para</span></a>
          <a href="javascript:;" class="dropdown-item" @click="rename" v-if="hasPermission(permissionsConstants.FOLDER_UPDATE)"><font-awesome-icon :icon="['fas', 'pen']" /> <span class="px-2">Renomear</span></a>
          <a href="javascript:;" class="dropdown-item" @click="deleteFolder" v-if="hasPermission(permissionsConstants.FOLDER_DELETE)"><font-awesome-icon :icon="['far', 'trash-alt']" /> <span class="px-2">Remover</span></a>
        </div>
      </div>
    </div>
    <div class="p-2 small">
      <div v-if="up">
        <i class="fa fa-arrow-left"></i> Voltar
      </div>
      <div v-else>
        {{ name }}
        <i class="fas fa-check" :class="[ colorCheck ? 'full' : 'partial' ]" v-if="hasReviewed" :title="`${countContent} arquivo(s), ${countReviewed} revisado(s)`"></i>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Sidebar from 'primevue/sidebar';
import FolderDetails from "@/components/sidebars/FolderDetails.vue";
import DirTree from '@/components/node/DirTree.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import permissionsConstants from '../../constants/permissions';

export default {
  components: { Sidebar, FolderDetails, DirTree },
  data: function () {
    return {
      showDetails: false,
      showDirTree: false
    }
  },
  methods: {
    ...mapActions('nodes', ['deleteNode', 'updateNode']),
    cd() {
      this.$router.push('/files/' + this.nodeId);
    },
    async rename() {
      const newDir = prompt('Entre com o novo nome de arquivo: ', this.name);

      const payload = {
        name    : newDir,
        folderId: this.folderId
      }

      if (!newDir) {
        this.$toast.add({severity:'error', summary: 'A pasta não pode ter nome vazio.'});
      } else {
        if (this.name != newDir) {
          try {
            await axios.post(`${this.apiEndpoint}/dir/${this.nodeId}`, payload);
            this.updateNode({ nodeId: this.nodeId, name: newDir });
          } catch (error) {
            if (error.response.data.code === 10002) { // TODO: review errors
              this.$toast.add({severity:'error', summary: 'Já existe um arquivo com o mesmo nome.'});
            } else {
              this.$toast.add({severity:'error', summary: 'Ocorreu um erro ao renomear o arquivo.'});
            }
          }
        }
      }
    },
    details() {
      this.showDetails = true;
    },
    hideDetails() {
      this.showDetails = false;
    },
    async deleteFolder() {
      if (confirm('Tem certeza que deseja remover esta pasta?')) {
        try {
          await this.deleteNode(this.nodeId);
          this.$toast.add({severity: 'success', summary: 'Pasta removida.', life: 3000});
        } catch (e) {
          switch(e.response.data.errCode) {
            case 'NOEMP':
              this.$toast.add({severity: 'error', summary: 'Pasta não está vazia.', life: 3000});
              break;
              
            default:
              this.$toast.add({severity: 'error', summary: 'Houve um erro ao remover a pasta.', life: 3000});
              break;
          }
        }
      }
    },
    toggleMove() {
      this.showDirTree = !this.showDirTree;
    },
    async move(newFolderId) {
      const body = {
        folderId: newFolderId
      }

      if (this.nodeId === newFolderId) {
        this.$toast.add({severity:'error', summary: 'A pasta de origem e destino não podem ser as mesmas.'});
        return;
      }

      try {
        await axios.post(`${this.apiEndpoint}/dir/move/${this.nodeId}`, body);
        this.$toast.add({severity:'success', summary: 'Pasta movida.', life: 3000});
        this.toggleMove();
        this.deleteNode(this.nodeId);
      } catch {
        this.$toast.add({severity:'error', summary: 'Houve um erro ao mover o pasta.'});
      }
    },
  },
  computed: {
    ...mapGetters(['hasPermission']),
    ...mapState(['apiEndpoint']),
    hasReviewed() {
      if (this.countReviewed > 0) {
        return true;
      } else {
        return false;
      }
    },
    colorCheck() {
      if (this.countContent == this.countReviewed) {
        return true;
      } else {
        return false;
      }
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    folderId: {
      type: Number
    },
    nodeId: {
      type: Number
    },
    up: {
      type: Boolean,
      default: false
    },
    countContent: {
      type: Number,
      default: 0
    },
    countReviewed: {
      type: Number,
      default: 0
    }
  },
  emits: ['refresh'],
  created() {
    this.permissionsConstants = permissionsConstants;
  }
};
</script>

<style scoped>
.fa-check {
  float: right;
}

.fa-check.full {
  color: green;
}
.fa-check.partial {
  color: yellow;
}
</style>