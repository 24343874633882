<template>
  <a class="dropdown-item" @click="setViewMode('thumb')" v-if="viewMode === 'icon'">Miniaturas</a>
  <a class="dropdown-item" @click="setViewMode('icon')" v-if="viewMode === 'thumb'">Ícones</a>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import usersApi from '../../api/users';

export default {
  computed: {
    ...mapGetters(['fetchSetting']),
    viewMode() {
      return this.fetchSetting('viewMode');
    }
  },
  methods: {
    ...mapActions(['setUserSetting']),
    async setViewMode(mode) {
      const setting = {
        setting: 'viewMode',
        value: mode
      };

      try {
        await usersApi.saveUserSetting([setting]);
        this.setUserSetting(setting);
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary : "Não foi possível mudar o modo de visualização. Tente novamente mais tarde.",
          life    : 3000,
        });
      }
    }
  }
}
</script>
