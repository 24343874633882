<template>
  <dir-tree v-if="isDirTreeVisible" @close="closeDirTree" @submit="move" />

  <a href="javascript:;" class="dropdown-item" @click="showDirTree"><font-awesome-icon :icon="['fas', 'copy']" /> <span class="px-2">Mover para</span></a>
</template>

<script>
import { mapActions } from 'vuex';
import DirTree from '@/components/node/DirTree.vue';

export default {
  components: { DirTree },
  props: {
    nodeId: {
      type: Number,
    },
  },
  data() {
    return {
      isDirTreeVisible: false
    }
  },
  methods: {
    ...mapActions('nodes', ['moveFile']),
    showDirTree() {
      this.isDirTreeVisible = true;
    },
    closeDirTree() {
      this.isDirTreeVisible = false;
    },
    async move(newFolderId) {
      try {
        await this.moveFile({ nodeId: this.nodeId, folderId: newFolderId });
        this.$toast.add({severity:'success', summary: 'Arquivo movido.', life: 3000});
        this.closeDirTree();
      } catch {
        this.$toast.add({severity:'error', summary: 'Houve um erro ao mover o arquivo.', life: 3000});
      }
    },
  }
};
</script>
