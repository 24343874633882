<template>
  <!-- BEGIN: Sidebar Group -->
  <!-- <div class="sidebar-group show" v-if="showDetails" @click.self="hideDetails"> -->

  <!-- </div> -->
  <!-- END: Sidebar Group -->

  <breadcrumb />

  <div class="row app-block mb-4">
    <div class="col-md-3 col-xxl-2 app-sidebar">
      <Sidebar />
    </div>

    <div class="col-md-9 col-xxl-10 app-content">
      <Topbar @reload="ls" @newDir="mkdir" />

      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xxl-2" v-if="nodeId != 0 && !searchMode && !loading">
          <folder :nodeId="parentInode" up="true" />
        </div>

        <div
          class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xxl-2"
          v-for="dir in dirs"
          :key="dir.id"
        >
          <folder :name="dir.name" :nodeId="dir.nodeId" :folderId="dir.folderId" :countContent="dir.countContent" :countReviewed="dir.countReviewed" @refresh="ls" />
        </div>

        <div
          class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xxl-2 file-card"
          v-for="file in files"
          :key="file.id"
        >
          <file
            :nodeId="file.nodeId"
            :name="file.name"
            :filesize="file.filesize"
            :mimetype="file.mimetype"
            :folderId="file.folderId"
            :thumbNodeId="file.thumbNodeId"
            :uploaded="file.uploaded"
            :deleted="file.deleted"
            :reviewed="file.reviewed"
            @details="fileDetails"
            @delete="deleteFile(file.id)"
          />
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xxl-2 file-card" v-for="times in 5" :key="times">
          <Skeleton v-if="loading" />
        </div>
      </div>

      <div class="row">
        <!-- <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <file filename="somefile.xlsx" filesize="120.85kb" mimetype="excel" />
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <file filename="somefile.xlsx" filesize="120.85kb" mimetype="excel" />
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <file filename="text.txt" filesize="12.50kb" mimetype="text" />
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <file
            filename="apresentação.ppt"
            filesize="120.50kb"
            mimetype="powerpoint"
          />
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <file filename="pacote.zip" filesize="120.50kb" mimetype="zip" />
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <file filename="texto.docx" filesize="120.50kb" mimetype="word" />
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <file filename="texto.pdf" filesize="120.50kb" mimetype="pdf" />
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <file filename="foto.jpg" filesize="120.50kb" mimetype="jpg" />
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <file filename="video.mp4" filesize="120.50kb" mimetype="mp4" />
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <file filename="audio.mp3" filesize="120.50kb" mimetype="mp3" />
        </div> -->
        <!-- <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div class="card app-file-list">
                    <div class="app-file-icon">
                      <i class="fa fa-file-text-o text-warning"></i>
                      <div
                        class="dropdown position-absolute top-0 right-0 mr-3"
                      >
                        <a
                          href="#"
                          class="btn btn-outline-light btn-sm"
                          data-bs-toggle="dropdown"
                        >
                          <i class="fa fa-ellipsis-h"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a href="#" class="dropdown-item">View Details</a>
                          <a href="#" class="dropdown-item">Share</a>
                          <a href="#" class="dropdown-item">Download</a>
                          <a href="#" class="dropdown-item">Copy to</a>
                          <a href="#" class="dropdown-item">Move to</a>
                          <a href="#" class="dropdown-item">Rename</a>
                          <a href="#" class="dropdown-item">Delete</a>
                        </div>
                      </div>
                    </div>
                    <div class="p-2 small">
                      <div>todo-list.txt</div>
                      <div class="text-muted">1.85kb</div>
                    </div>
                  </div>
                </div> -->

        <!-- <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div class="card app-file-list">
                    <div class="app-file-icon">
                      <i class="fa fa-file-powerpoint-o text-secondary"></i>
                      <div
                        class="dropdown position-absolute top-0 right-0 mr-3"
                      >
                        <a
                          href="#"
                          class="btn btn-outline-light btn-sm"
                          data-bs-toggle="dropdown"
                        >
                          <i class="fa fa-ellipsis-h"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a href="#" class="dropdown-item">View Details</a>
                          <a href="#" class="dropdown-item">Share</a>
                          <a href="#" class="dropdown-item">Download</a>
                          <a href="#" class="dropdown-item">Copy to</a>
                          <a href="#" class="dropdown-item">Move to</a>
                          <a href="#" class="dropdown-item">Rename</a>
                          <a href="#" class="dropdown-item">Delete</a>
                        </div>
                      </div>
                    </div>
                    <div class="p-2 small">
                      <div>presentation-file.pptx</div>
                      <div class="text-muted">10.50kb</div>
                    </div>
                  </div>
                </div> -->
        <!-- <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div class="card app-file-list">
                    <div class="app-file-icon">
                      <i class="fa fa-file-excel-o text-success"></i>
                      <div
                        class="dropdown position-absolute top-0 right-0 mr-3"
                      >
                        <a
                          href="#"
                          class="btn btn-outline-light btn-sm"
                          data-bs-toggle="dropdown"
                        >
                          <i class="fa fa-ellipsis-h"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a href="#" class="dropdown-item">View Details</a>
                          <a href="#" class="dropdown-item">Share</a>
                          <a href="#" class="dropdown-item">Download</a>
                          <a href="#" class="dropdown-item">Copy to</a>
                          <a href="#" class="dropdown-item">Move to</a>
                          <a href="#" class="dropdown-item">Rename</a>
                          <a href="#" class="dropdown-item">Delete</a>
                        </div>
                      </div>
                    </div>
                    <div class="p-2 small">
                      <div>contact-list.xlsx</div>
                      <div class="text-muted">8.7mb</div>
                    </div>
                  </div>
                </div> -->
        <!-- <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div class="card app-file-list">
                    <div class="app-file-icon">
                      <i class="fa fa-file-zip-o text-primary"></i>
                      <div
                        class="dropdown position-absolute top-0 right-0 mr-3"
                      >
                        <a
                          href="#"
                          class="btn btn-outline-light btn-sm"
                          data-bs-toggle="dropdown"
                        >
                          <i class="fa fa-ellipsis-h"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a href="#" class="dropdown-item">View Details</a>
                          <a href="#" class="dropdown-item">Share</a>
                          <a href="#" class="dropdown-item">Download</a>
                          <a href="#" class="dropdown-item">Copy to</a>
                          <a href="#" class="dropdown-item">Move to</a>
                          <a href="#" class="dropdown-item">Rename</a>
                          <a href="#" class="dropdown-item">Delete</a>
                        </div>
                      </div>
                    </div>
                    <div class="p-2 small">
                      <div>website-html-files.zip</div>
                      <div class="text-muted">100mb</div>
                    </div>
                  </div>
                </div> -->
        <!-- <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div class="card app-file-list">
                    <div class="app-file-icon">
                      <i class="fa fa-file-word-o text-info"></i>
                      <div
                        class="dropdown position-absolute top-0 right-0 mr-3"
                      >
                        <a
                          href="#"
                          class="btn btn-outline-light btn-sm"
                          data-bs-toggle="dropdown"
                        >
                          <i class="fa fa-ellipsis-h"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a href="#" class="dropdown-item">View Details</a>
                          <a href="#" class="dropdown-item">Share</a>
                          <a href="#" class="dropdown-item">Download</a>
                          <a href="#" class="dropdown-item">Copy to</a>
                          <a href="#" class="dropdown-item">Move to</a>
                          <a href="#" class="dropdown-item">Rename</a>
                          <a href="#" class="dropdown-item">Delete</a>
                        </div>
                      </div>
                    </div>
                    <div class="p-2 small">
                      <div>articles.docx</div>
                      <div class="text-muted">100.25kb</div>
                    </div>
                  </div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/header/Breadcrumb.vue";
import Sidebar from '@/components/node/Sidebar.vue';
import Topbar from '@/components/TopBar/Topbar.vue';
import Folder from "@/components/node/Folder.vue";
import File from "@/components/node/File.vue";
import Skeleton from '@/components/node/Skeleton.vue';
import axios from 'axios';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  components: { Folder, File, Sidebar, Topbar, Breadcrumb, Skeleton },
  data() {
    return {
      showDetails: false,
      detailsInode: 0,
      parentInode: 0
    };
  },
  props: ["nodeId"],
  computed: {
    ...mapGetters({
      apiEndpoint: 'apiEndpoint',
      sort       : 'search/sort',
      order      : 'search/order',
      searchMode : 'searchMode',
      itemCount  : 'nodes/itemCount',
      nodeId     : 'nodeId'
    }),
    ...mapGetters('nodes', {dirs: 'getDirs'}),
    ...mapGetters('nodes', {files: 'getFiles'}),
    ...mapGetters('nodes', ['noNodes']),
    ...mapState(['loading']),
    searchQuery() {
      return this.$route.query.query;
    },
  },
  methods: {
    ...mapActions('nodes', ['clearNodes', 'setNodes']),
    ...mapActions('search', ['advancedSearch']),
    ...mapActions(['searchModeOn', 'searchModeOff', 'cd', 'pwd', 'setLoadingOn', 'setLoadingOff']),
    deleteFile(inode) {
      let obj = this.files.find(f => f.id == inode);
      if(obj) {
        obj.deleted = true;
      }
    },
    changeOrder(newSort) {
      const sort = this.$store.getters['search/sort'];

      if (sort === newSort) {
        this.$store.dispatch('search/switchOrder');
      } else {
        this.$store.dispatch('search/setSort', newSort);
        this.$store.dispatch('search/setOrder', 'asc');
      }

      this.ls();
    },
    async ls() {
      this.searchModeOff();

      this.setLoadingOn();

      this.clearNodes();

      if (this.nodeId === 'null') {
        this.cd(0);
        this.$router.push('/files/0');
      } else {
        try {
          if (this.nodeId !== 0) {
            const node = await axios.get(`${this.apiEndpoint}/dir/details/${this.nodeId}`);
    
            this.parentInode = node.data.folderId;
          }
    
          const response = await axios.get(`${this.apiEndpoint}/dir/${this.nodeId}?sort=${this.sort}&order=${this.order}`);
    
          this.setNodes(response.data);
        } catch {
          this.$toast.add({severity:'error', summary: 'Houve um erro ao carregar as informações.', life: 3000});
        } finally {
          this.setLoadingOff();
        }
      }

      // })
      // .catch((error) => {
      //   if (error.response.status === 404) {
      //     this.$router.push('/files/0');
      //   } else {
      //     this.$toast.add({severity:'error', summary: 'Houve um erro ao carregar as informações.', life: 3000});
      //   }
      //   this.$store.dispatch('loading', false);
      // });
          // .catch((error) => { // TODO: code
    },
    async searchFiles() {
      this.setLoadingOn();

      this.searchModeOn();
      this.clearNodes();

      try {
        const searchResult = await axios.get(`${this.apiEndpoint}/search?query=${this.searchQuery}&sort=${this.sort}&order=${this.order}`);

        this.setNodes(searchResult.data);
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'Houve um erro ao buscar no banco de dados.'});
      } finally {
        this.setLoadingOff();
      }
    },
    async advancedSearchFiles() {
      this.setLoadingOn();

      this.searchModeOn();
      this.clearNodes();

      try {
        let payload;

        if (this.$route.query.tags) {
          const tags = JSON.parse(this.$route.query.tags);
          payload = {...this.$route.query, tags};
        } else {
          payload = {...this.$route.query};
        }

        await this.advancedSearch(payload);
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'Houve um erro ao buscar no banco de dados.'});
      } finally {
        this.setLoadingOff();
      }

    },
  },
  watch: {
    nodeId: {
      handler: function(newVal) {
        if (newVal) {
          this.cd(this.nodeId);
          this.ls();
          this.pwd();
        }
      }
    },
    "$store.state.nodeId": {
      handler: function () {
        this.ls();
      },
      immediate: true, // provides initial (not changed yet) state
    },
    "$route.query.query": {
      handler: function () {
        this.searchFiles();
      }
    },
    "$route.query": {
      handler: function() {
        if (this.$route.name === 'advancedSearch') {
          this.advancedSearchFiles();
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('tags/refreshTags');

    if (this.$route.name === 'files') {
      this.cd(this.nodeId);
      this.ls();
      this.pwd();
    }

    if (this.$route.name === 'search') {
      this.searchFiles();
    }

    if (this.$route.name === 'advancedSearch') {
      this.advancedSearchFiles();
    }
  }
};
</script>

<style scoped>
.dropdown-item.disabled {
  color: gray;
}
.file-card.deleted {
  display: none;
}
</style>