<template>
  <div class="card">
    <div class="card-body" v-if="hasPermission(permissionsConstants.FILE_CREATE)">
      <upload v-if="allowUpload" />
    </div>
    <div class="app-sidebar-menu">
      <total-items />

      <create-folder v-if="hasPermission(permissionsConstants.FOLDER_CREATE)" />

      <disk-usage />
    </div>
  </div>
</template>

<script>
import Upload from '@/components/node/Upload.vue';
import CreateFolder from './CreateFolder.vue';
import { mapGetters } from 'vuex'
import permissionsConstants from '../../constants/permissions';
import DiskUsage from './DiskUsage.vue';
import TotalItems from './TotalItems.vue';

export default {
  components: { Upload, CreateFolder, DiskUsage, TotalItems },
  props: {
    allowUpload: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    ...mapGetters(['hasPermission']),
  },
  created() {
    this.permissionsConstants = permissionsConstants;
  }
}
</script>
