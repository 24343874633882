<template>
  <ul class="list-group list-group-flush">
        <li
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-start
          "
        >
          <div class="ms-2 me-auto">
            <font-awesome-icon
              class="width-15 height-15 mr-2"
              :icon="['far', 'folder']"
            />
            Total de itens
          </div>
          {{ itemCount }}
        </li>
        <!-- TODO: count -->
        <!-- <li
              class="list-group-item d-flex justify-content-between align-items-start"
            >
              <div class="ms-2 me-auto">
                <font-awesome-icon
                  class="width-15 height-15 mr-2"
                  :icon="['far', 'star']"
                />
                Avaliados
              </div>
              10
            </li> -->
        <!-- <li
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-start
          "
        >
          <div class="ms-2 me-auto">
            <font-awesome-icon
              class="width-15 height-15 mr-2"
              :icon="['far', 'trash-alt']"
            />
            Lixeira
          </div>
        </li> -->
      </ul>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('nodes', ['itemCount']),
  }
}
</script>
